<template>
  <div class="page df fdc jc-sb">
    <div>
      <!-- S 背景图 -->
      <img v-if="login_img" class="img-bg" :src="login_img" alt="" />
      <img
        v-else-if="!login_img && img_request"
        class="img-bg"
        src="../../assets/img_denglu_bg@3x.png"
        alt=""
      />
      <!-- E 背景图 -->
      <!-- E tab -->

      <h3 class="title">Hi，请登录账号</h3>

      <!-- S 表单 -->
      <div class="form">
        <div class="form-group border-bottom-1px">
          <label for="mobile" class="form-label">
            <i class="icon icon-mobile"></i>
          </label>
          <input
            required
            v-model="mobile"
            id="mobile"
            class="form-control"
            placeholder="请输入卡号"
          />
        </div>
        <div class="form-group border-bottom-1px">
          <label for="code" class="form-label">
            <i class="icon icon-sms-code"></i>
          </label>
          <input
            type="password"
            required
            v-model="password"
            id="code"
            class="form-control"
            placeholder="请输入密码"
          />
        </div>
      </div>
      <div class="form-bottom"><p class="tip">温馨提示：为了保证您的资金安全，首次登录后请尽快修改密码!</p></div>
    </div>
    <div>
      <!-- S 注册按钮 -->
      <div
        class="btn submit-btn flex-center"
        v-if="!is_request"
        @click="submit"
      >
        立即登录
      </div>
      <div class="btn submit-btn flex-center" v-else>立即登录</div>
      <!-- E 注册按钮 -->
      <!-- S 底部文案-->
      <p class="login-text">{{ login_text }}</p>
      <!-- E 底部文案-->
    </div>
  </div>
</template>

<script>
import { getColor } from "@/services";
import { kflogin } from "@/services";
import { toolTip, inputMixins } from "@/mixins";
import { debounce } from "@/utils/debounce";
import { mapState } from "vuex";
import { getStore } from "@/utils/common";

export default {
  name: "fkLogin",
  computed: {
    ...mapState({
      userInfo: state => state.userInfo, //用户信息
      business_id: state => state.business_id
    })
  },
  mixins: [toolTip, inputMixins],
  data() {
    return {
      mobile: "", // 卡号
      password: "", //密码
      comId: "793", //单位编号暂时一家写死
      title: "",
      contents: "",
      is_request: false,
      login_img: "", //登录图
      img_request: false,
      login_text: "" //登录文案
    };
  },
  created() {},
  methods: {
    // 获取主题色
    async getThemeColor() {
      try {
        const res = await getColor(this.$store.state.userInfo.business_id);
        this.img_request = true;
        if (res.code === 0) {
          const { login_img, login_text } = res.data;
          this.login_img = login_img;
          this.login_text = login_text;
          this.$forceUpdate();
        }
      } catch (error) {
        console.warn("Something bad happened in api getColor: ", error);
      }
    },
    // 提交表单
    submit: debounce(async function() {
      this.is_request = true;
      let password = /^[0-9a-zA-Z]*$/g.test(this.password);
      let mobile = /^[0-9a-zA-Z]*$/g.test(this.mobile);
      if (!this.mobile) {
        this.$dialog.toast(
          {
            mes: "请输入卡号"
          },
          300
        );
        this.is_request = false;
        return;
      }
      if (!this.password) {
        this.$dialog.toast(
          {
            mes: "请输入密码"
          },
          300
        );
        this.is_request = false;
        return;
      }
      if (!password || !mobile) {
        this.$dialog.toast(
          {
            mes: "仅允许输入数字或字母,请重新输入!"
          },
          600
        );
        this.mobile = "";
        this.password = "";
        this.is_request = false;
        return;
      }
      try {
        let comId = 1975;
        let bid = JSON.parse(getStore('businessInfo')).id;
        console.log(JSON.parse(getStore('businessInfo')).id,'this.business_id')
        if(bid != '2052'){
          comId = 2765
        }else{
          comId = 1975;
        }
        const res = await kflogin(this.mobile, this.password,comId);
        this.is_request = false;
        if (res.code === 0) {
           this.$dialog.toast(
            {
              mes: res.msg
            },
            300
          );
          this.$router.replace("home");
        } else {
          this.$dialog.toast(
            {
              mes: res.msg
            },
            300
          );
        }
      } catch (e) {
        this.is_request = false;
        console.log(e);
      }
    }, 300)
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    if (JSON.stringify(this.userInfo) !== "{}") {
      this.$router.replace("home");
    }
    this.getThemeColor();
  }
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";
.nickname {
  padding: 0.2rem 0 0 0.6rem;
}
.form-bottom{
  padding: .2rem .54rem .2rem .56rem;
  p{
    line-height: 0.4rem;
    color: red;
  }
}
.page {
  .bj(#fff);
  padding-bottom: 0.6rem;

  .img-bg {
    .wh(100%, 3.64rem);
  }
  .tab-list {
    position: relative;
    .wh(100%, 1rem);
    margin-bottom: 0.4rem;
    overflow: hidden;

    li {
      .wh(50%, 100%);
      float: left;
      position: relative;
      overflow: hidden;
      z-index: 0;

      .btn {
        position: relative;
        .wh(100%, 100%);
        .sc(@fontsize-medium, @color-grey);
        line-height: 0.4rem;
        text-align: center;
      }

      &.active {
        .btn {
          .sc(@fontsize-large-x, @color-dark-grey);
          line-height: 0.5rem;
          font-weight: bold;

          &:after {
            content: "";
            .cl;
            bottom: 0;
            display: block;
            .wh(2rem, 0.04rem);
            background-color: var(--main-color);
            border-radius: 0.03rem;
          }
        }
      }
    }
  }

  .title {
    padding-left: 0.52rem;
    margin-bottom: 0.18rem;
    .sc(@fontsize-large-xxx, @color-dark-grey);
    line-height: 0.66rem;
    font-weight: bold;
  }

  .form {
    width: 100%;
    padding-left: 0.56rem;
    padding-right: 0.54rem;

    .form-group {
      position: relative;
      display: flex;
      align-items: center;
      padding-top: 0.4rem;
      padding-bottom: 0.3rem;
      box-sizing: border-box;
      z-index: 1;

      .form-label {
        position: absolute;
        top: 0.39rem;
        left: 0;
        .sc(@fontsize-medium, @color-dark-grey);
        line-height: 0.4rem;
        z-index: 4;

        .icon {
          display: inline-block;
          .wh(0.32rem, 0.32rem);
        }

        .icon-mobile {
          width: 0.24rem;
          .bis("../../assets/icon_denglu_shouji");
        }

        .icon-img-code {
          height: 0.28rem;
          .bis("../../assets/icon_denglu_yanzhengma");
        }

        .icon-sms-code {
          .bis("../../assets/icon_denglu_shuruyanzhengma");
        }
      }

      .form-control {
        position: relative;
        .wh(100%, 98%);
        padding-left: 0.48rem;
        border: none;
        box-sizing: border-box;
        .sc(@fontsize-medium, @color-dark-grey);
        line-height: 0.4rem;
        transition: 0.3s;
        z-index: 3;

        &::-webkit-input-placeholder {
          color: #b3b3b3;
        }
      }

      .img-box {
        .ct;
        right: 0;
        .wh(2.4rem, 0.8rem);
        z-index: 4;

        .img-code {
          .wh(100%, 100%);
        }
      }

      .send-btn,
      .countdown {
        .ct;
        right: 0;
        .wh(1.68rem, 0.52rem);
        .sc(@fontsize-small, #fff);
        line-height: 0.52rem;
        text-align: center;
        white-space: nowrap;
        border-radius: 0.26rem;
        z-index: 4;
      }

      .send-btn {
        background: var(--main-color);
        .touch-active;
      }

      .countdown {
        background: @color-light-grey;
      }
    }
  }

  .form-bottom {
    .protocol-wrap {
      display: flex;
      align-items: center;

      .icon-wrap {
        .wh(0.28rem, 0.28rem);
        margin-right: 0.1rem;
      }

      .select-item {
        .wh(0.28rem, 0.28rem);
        color: @color-light-grey;
      }

      .selected-item {
        .wh(0.28rem, 0.28rem);
        color: var(--main-color);
      }

      .gray-txt {
        margin-right: 0.1rem;
        .sc(@fontsize-small, @color-dark-grey);
        line-height: 100%;
      }

      .protocol {
        .sc(@fontsize-medium, var(--main-color));
        line-height: 0.4rem;
      }
    }
  }

  .submit-btn {
    .wh(6.4rem, 0.88rem);
    margin: 0 auto;
    .sc(@fontsize-large, #fff);
    line-height: 0.88rem;
    text-align: center;
    white-space: nowrap;
    background-color: var(--main-color);
    border-radius: 0.5rem;
    overflow: hidden;
    .touch-active(var(--main-color));
  }

  .alert-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000000;
    backface-visibility: hidden;

    .alert-content {
      .cl;
    }
  }

  .protocol-alert {
    .alert-content {
      top: 0.7rem;

      .protocol-container {
        .wh(6.7rem, 8.86rem);
        margin-bottom: 0.4rem;
        background-color: #fff;
        border-radius: 0.08rem;
        border: 0.04rem solid var(--main-color);
        overflow: hidden;

        .protocol-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          .wh(6.5rem, 8.66rem);
          padding: 0.3rem 0.2rem;
          background-color: #fff;
          border-radius: 0.08rem;
          border: 0.04rem solid var(--main-color);
          // overflow: hidden;

          .top-title {
            display: flex;
            align-items: center;
            margin-bottom: 0.76rem;

            .dot {
              display: inline-block;
              .wh(0.08rem, 0.08rem);
              background-color: @color-light-grey;
              border-radius: 50%;
            }

            .txt {
              margin: 0 0.2rem;
              .sc(@fontsize-medium, @color-light-grey);
              line-height: 0.44rem;
              letter-spacing: -0.02rem;
            }
          }

          .protocol-title {
            margin-bottom: 0.6rem;
            .sc(@fontsize-large-x, var(--main-color));
            line-height: 0.5rem;
            letter-spacing: 0.01rem;
            font-weight: bold;
          }

          .protocol-content {
            max-height: 60%;
            .sc(0.26rem, @color-grey);
            line-height: 0.44rem;
            word-wrap: break-word;
            word-break: break-all;
            overflow: hidden;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
          }
        }
      }

      .close-btn {
        .wh(0.68rem, 0.68rem);
        margin: 0 auto;
        .bis("../../assets/icon_denglu_guanbi");
      }
    }
  }

  .login-text {
    text-align: center;
    width: 100%;
    margin-top: 0.3rem;
  }
}
</style>
